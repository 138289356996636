import { BooleanField, NumberField, Show, SimpleShowLayout, TextField } from 'react-admin';
import { Card, CardContent, Grid } from '@mui/material';
import { BackButton } from '../../react-admin/components/BackButton';

export const SubscriptionPlanDetails = () => (
    <Card>
        <CardContent>
            <Show>
                <Grid container spacing={2}>
                    <Grid xs={6} item>
                        <SimpleShowLayout>
                            <TextField source="name"/>
                            <TextField source="title"/>
                            <TextField source="subtitle"/>
                            <TextField source="availability"/>
                            <NumberField source="amount"/>
                            <NumberField source="creditAmount"/>
                            <NumberField source="order"/>
                            <TextField source="currency"/>
                            <TextField source="interval"/>
                            <BooleanField source="isDefault"/>
                            <BooleanField source="isEnabled"/>
                            <TextField source="stripePlanId"/>
                            <TextField source="renewalPeriod"/>
                            <BackButton/>
                        </SimpleShowLayout>
                    </Grid>
                </Grid>
            </Show>
        </CardContent>
    </Card>
);
