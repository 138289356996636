// in src/Dashboard.js
import * as React from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';

const Dashboard = () => (
    <Card>
        <CardHeader title="Welcome to NPS administration" />
        <CardContent>
            {/*<StatsList />*/}
        </CardContent>
    </Card>
);
export default Dashboard;
