import React from 'react';

import { Layout as RaLayout, LayoutProps } from 'react-admin';

import { Menu } from './Menu';

export const Layout: React.FC<LayoutProps> = (props) => (
    <RaLayout
        {...props}
        style={{
            width: '100vw',
            height: '100vh',
        }}
        menu={Menu}
    />
);
