import {
    BooleanField,
    DateField,
    EditButton,
    NumberField,
    Show,
    SimpleShowLayout,
    TextField,
    useShowContext,
    useCreatePath,
} from 'react-admin';

const SettingsActionsActions = ({ id }: { id: string }) => {
  const createPath = useCreatePath();

  return (
    <EditButton
      resource={'settings'}
      to={createPath({ resource: 'settings', type: 'edit', id: id })}
      label={'Save'}
    ></EditButton>
  );
};

export const SettingsDetails = () => {
    const { record } = useShowContext();
    const { id } = record;

    return (
        <Show resource="settings" actions={<SettingsActionsActions id={id} />}>
          <SimpleShowLayout>
              <NumberField source="orderPaymentSplitPercent" />
              <BooleanField source="idRequired" />
              <BooleanField source="allowAccessForEveryone" />
              <BooleanField source="allowAccessForStaff" />
              <DateField showTime={false} source="allowAccessForSubscriptionCreatedBefore" label="Date" />
              <TextField source="allowAccessForSubscriptionPlans" />
          </SimpleShowLayout>
        </Show>
    );
};
