import { BrowserRouter, Route } from 'react-router-dom';

import Dashboard from './components/common/Dashboard';
import { Admin, CustomRoutes, Resource } from 'react-admin';
import { Layout } from './react-admin/components/Layout';
import dataProvider from './react-admin/providers/dataProvider';
import { WalletList } from './components/WalletList';
import { WalletDetails } from './components/WalletDetails';
import { SubscriptionPlanList } from './components/SubscriptionPlanList';
import { SubscriptionPlanDetails } from './components/SubscriptionPlanDetails';
import { SubscriptionPlanEdit } from './components/SubscriptionPlanEdit';
import { SubscriptionPlanCreate } from './components/SubscriptionPlanCreate';
import { SettingsList } from './components/SettingsList';
import SettingsEdit from './components/SettingsEdit';
import { SettingsDetails } from './components/SettingsDetails';
import { KYCList } from './components/KYCList';
import { PaymentList } from './components/PaymentList';
import { createCognitoAuthProvider } from './react-admin/providers/cognitoAuthProvider';
import { CognitoLogin } from './components/CognitoLogin';

const provider = createCognitoAuthProvider();

const App = () => {
    return (
        <BrowserRouter>
            <Admin
                dashboard={Dashboard}
                layout={Layout}
                dataProvider={dataProvider}
                authProvider={provider}
                loginPage={CognitoLogin}
                title={'Payment System Admin'}
                disableTelemetry={true}
            >
                <Resource name="wallets" list={WalletList} show={WalletDetails} />
                <Resource name="plans" list={SubscriptionPlanList} show={SubscriptionPlanDetails} edit={SubscriptionPlanEdit} create={SubscriptionPlanCreate}/>
                <Resource name="settings" list={SettingsList} edit={SettingsEdit} show={SettingsDetails} />
                <Resource name="kyc" list={KYCList} />
                <Resource name="payments" list={PaymentList} />
                <CustomRoutes>
                    <Route path="wallets" element={<WalletList />} />
                    <Route path="settings" element={<SettingsList />} />
                    <Route path="plans" element={<SubscriptionPlanList />} />
                    <Route path="kyc" element={<KYCList />} />
                    <Route path="/wallets/:userId/payments" element={<PaymentList />}/>
                </CustomRoutes>
            </Admin>
        </BrowserRouter>
    );
};

export default App;
