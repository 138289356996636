import IHandler from './IHandler';
import {
    GetListParams,
    GetListResult,
    GetOneParams,
    GetOneResult,
    RaRecord,
    UpdateParams,
    UpdateResult,
} from 'react-admin';
import { getJson, processApiError, putJson } from '../../../utils/api';

export default class SettingsHandler implements IHandler {
    static route = '/admin/settings';
    static resourceIdName = 'timestamp';

    static async getListHandler<RecordType extends RaRecord = any>(
        resource?: string,
        params?: GetListParams
    ): Promise<GetListResult<RecordType>> {
        const query: { [key: string]: any } = {
            pageSize: params?.pagination.perPage ?? 10,
        };

        return getJson(`${this.route}?${new URLSearchParams(query).toString()}`)
            .then(async (response) => {
                let { data: responseData } = await response.json();

                const nextToken = responseData.nextToken;
                responseData = responseData?.items || [];

                responseData = responseData.map((data: any) => {
                    const result = {
                        ...data,
                    };

                    // result.id = data[this.resourceIdName];
                    // delete result[this.resourceIdName];
                    return {
                        ...result,
                    };
                });

                return {
                    data: responseData,
                    pageInfo: {
                        hasNextPage: !!nextToken,
                        nextPageToken: nextToken ?? undefined,
                    },
                };
            })
            .catch((response) => {
                if (response.status === 400) {
                    return {
                        data: [],
                        total: 0,
                    };
                }
                return Promise.reject({
                    status: response.status,
                    error: response.statusText,
                    message: response.statusText,
                });
            });
    }

    static async getOneHandler<RecordType extends RaRecord = any>(
        resource: string,
        params?: GetOneParams<any>
    ): Promise<GetOneResult<RecordType>> {
        const id = params?.id;
        return getJson(`${this.route}/${id}`).then(async (response) => {
            const { data } = await response.json();

            data.id = id;
            return {
                data: {
                    ...data,
                },
            };
        });
    }

    static async updateHandler<RecordType extends RaRecord = any>(
        resource: string,
        params: UpdateParams<any>
    ): Promise<UpdateResult<RecordType>> {
        const { id } = params;
        params.id = id === 'undefined' ? '' : id;

        const payload = params.data;

        return putJson(`/${this.route}/${id}`, payload, process.env.REACT_APP_LOCAL_API).then(async (response) => {
            const { data } = await response.json();

            data.id = id;
            return {
                data: {
                    ...data,
                },
            };
        }).catch(processApiError);
    }
}
