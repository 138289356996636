import IHandler from './IHandler';
import { GetListParams, GetListResult, RaRecord } from 'react-admin';
import { getJson } from '../../../utils/api';

export default class PaymentListHandler implements IHandler {
    static prevNextToken: Record<number, string> = {};
    static route = '/admin/payments/list';

    static async getListHandler<RecordType extends RaRecord = any>(
        resource?: string,
        params?: GetListParams
    ): Promise<GetListResult<RecordType>> {
        const query: { [key: string]: any } = {
            limit: params?.pagination.perPage ?? 10,
            ...params?.filter
        };

        const page = params?.pagination.page ?? 1;
        if (page > 1 && PaymentListHandler.prevNextToken[page - 1] != null) {
            query['nextToken'] = PaymentListHandler.prevNextToken[page - 1];
        }
        return getJson(`${this.route}?${new URLSearchParams(query).toString()}`, process.env.REACT_APP_LOCAL_API)
            .then(async (response) => {
                let { data: responseData } = await response.json();
                const nextToken = responseData?.nextToken;
                PaymentListHandler.prevNextToken[page] = JSON.stringify(nextToken);
                return {
                    data: responseData.payments ?? [],
                    pageInfo: {
                        hasNextPage: !!nextToken,
                        nextPageToken: nextToken ?? undefined,
                    },
                };
            })
            .catch((response) => {
                if (response.status === 400) {
                    return {
                        data: [],
                        total: 0,
                    };
                }
                return Promise.reject({
                    status: response.status,
                    error: response.statusText,
                    message: response.statusText,
                });
            });
    }
}