import { Create } from 'react-admin';
import { Card, CardContent } from '@mui/material';
import { PlanEditForm } from '../SubscriptionPlanEdit';

export const SubscriptionPlanCreate = () => (
    <Card>
        <CardContent>
            <Create title="Create subscription plan" resource="plans">
                <PlanEditForm/>
            </Create>
        </CardContent>
    </Card>
);
