import IHandler from './IHandler';
import { GetListParams, GetListResult, GetOneParams, GetOneResult, RaRecord } from 'react-admin';
import { getJson, postJson } from '../../../utils/api';

export default class WalletListHandler implements IHandler {
    static prevNextToken: Record<number, string> = {};
    static route = '/admin/wallet/search';
    static resourceIdName = 'userId';

    static async getListHandler<RecordType extends RaRecord = any>(
        resource?: string,
        params?: GetListParams
    ): Promise<GetListResult<RecordType>> {
        const query: { [key: string]: any } = {
            limit: params?.pagination.perPage ?? 10,
            ...params?.filter
        };

        const page = params?.pagination.page ?? 1;
        if (page > 1 && WalletListHandler.prevNextToken[page - 1] != null) {
            query['nextToken'] = WalletListHandler.prevNextToken[page - 1];
        }
        return getJson(`${this.route}?${new URLSearchParams(query).toString()}`, process.env.REACT_APP_LOCAL_API)
            .then(async (response) => {
                let { data: responseData } = await response.json();
                const nextToken = responseData?.nextToken;
                WalletListHandler.prevNextToken[page] = JSON.stringify(nextToken);
                responseData = responseData?.wallets?.map((data: any) => {
                    const result = {
                        ...data,
                    };

                    result.id = data[this.resourceIdName];
                    delete result[this.resourceIdName];
                    return {
                        ...result,
                    };
                });
                return {
                    data: responseData,
                    pageInfo: {
                        hasNextPage: !!nextToken,
                        nextPageToken: nextToken ?? undefined,
                    },
                };
            })
            .catch((response) => {
                if (response.status === 400) {
                    return {
                        data: [],
                        total: 0,
                    };
                }
                return Promise.reject({
                    status: response.status,
                    error: response.statusText,
                    message: response.statusText,
                });
            });
    }

    static async getOneHandler<RecordType extends RaRecord = any>(
        resource: string,
        params?: GetOneParams
    ): Promise<GetOneResult<RecordType>> {
        const id = params?.id;
        return getJson(`${this.route}?userId=${id}`, process.env.REACT_APP_LOCAL_API).then(async (response) => {
            const { data } = await response.json();

            const d = data.wallets[0];

            d.id = d[this.resourceIdName];
            delete d[this.resourceIdName];
            console.log(d);
            return {
                data: {
                    ...d,
                },
            };
        });
    }

    static async cancelSubscription(userId: string, reason: string, onSuccess: () => void, onFailure: (err: string) => void) {
        return postJson(`/admin/wallet/${userId}/subscription/cancel`, { reason }, process.env.REACT_APP_LOCAL_API)
        .then(async (response) => {
            const { data } = await response.json();

            const d = data;
            d.id = d[this.resourceIdName];
            delete d[this.resourceIdName];
            onSuccess();
            return {
                data: {
                    ...d,
                },
            };
        })
        .catch((response) => {
            onFailure(`${response.status}:${response.error.message ?? JSON.stringify(response.error, null, 2)}:${response.message}`);
            return Promise.reject({
                status: response.status,
                error: response.statusText,
                message: response.statusText,
            });
        });
    }

    static async changeSubscription(userId: string, payload: { planId: string, reason?: string }, onSuccess: () => void, onFailure: (err: string) => void) {
        return postJson(`/admin/wallet/${userId}/subscription/plan`, { ...payload }, process.env.REACT_APP_LOCAL_API)
        .then(async (response) => {
            const { data } = await response.json();

            const d = data;
            d.id = d[this.resourceIdName];
            delete d[this.resourceIdName];
            onSuccess();
            return {
                data: {
                    ...d,
                },
            };
        })
        .catch((response) => {
            onFailure(`${response.status}:${response.error.message ?? JSON.stringify(response.error, null, 2)}:${response.message}`);
            return Promise.reject({
                status: response.status,
                error: response.statusText,
                message: response.statusText,
            });
        });
    }

    static async changePaymentDay(userId: string, payload: { paymentDay: string, reason?: string }, onSuccess: () => void, onFailure: (err: string) => void) {
        return postJson(`/admin/wallet/${userId}/subscription/paymentDay`, { ...payload }, process.env.REACT_APP_LOCAL_API)
        .then(async (response) => {
            const { data } = await response.json();

            const d = data;
            d.id = d[this.resourceIdName];
            delete d[this.resourceIdName];
            onSuccess();
            return {
                data: {
                    ...d,
                },
            };
        })
        .catch((response) => {
            onFailure(`${response.status}:${response.error.message ?? JSON.stringify(response.error, null, 2)}:${response.message}`);
            return Promise.reject({
                status: response.status,
                error: response.statusText,
                message: response.statusText,
            });
        });
    }

    static async chargeAll(onSuccess: () => void, onFailure: (err: string) => void) {
        return postJson(`/admin/wallet/payall`, {}, process.env.REACT_APP_LOCAL_API)
        .then(async (response) => {
            const { data } = await response.json();

            const d = data;
            d.id = 'success';

            onSuccess();
            return {
                data: {
                    ...d,
                },
            };
        })
        .catch((response) => {
            onFailure(`${response.status}:${response.error.message ?? JSON.stringify(response.error, null, 2)}:${response.message}`);
            return Promise.reject({
                status: response.status,
                error: response.statusText,
                message: response.statusText,
            });
        });
    }

}