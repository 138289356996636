import { useRecordContext } from 'react-admin';

export interface CustomUrlFieldProps {
    prefix: string;
    source: string;
    target: string;
    rel: string;
}

export const get = (o: any, path: string) => path.split('.').reduce((o = {}, key) => o[key], o);

const CustomUrlField = (props: CustomUrlFieldProps) => {
    const { source, target, rel, prefix } = props;
    const record = useRecordContext(props);
    const value = record && get(record, source);

    if (value == null) {
        return null;
    }

    return (
        <a href={`${prefix}${value}`} target={target} rel={rel}>
            {value}
        </a>
    );
};

CustomUrlField.defaultProps = {
    addLabel: true,
};

export default CustomUrlField;