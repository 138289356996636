import { CognitoAuthProviderOptionsIds, CognitoAuthProvider } from 'ra-auth-cognito';
import { AuthProvider } from 'react-admin';
import { CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';

export const prepareOAuthLoginUrl = (options?: Partial<CognitoAuthProviderOptionsIds>) => {
  const oauthOptions = prepareBaseCognitoAuthProviderOptions(options);

  const redirect_uri = oauthOptions.redirect_uri ?? `${window.location.origin}/auth-callback`;
  const scope = oauthOptions.scope || ['openid', 'email', 'profile', 'aws.cognito.signin.user.admin'];
  return `${oauthOptions.hostedUIUrl}/login?client_id=${oauthOptions.clientId}&response_type=token&scope=${scope.join(
    '+'
  )}&redirect_uri=${redirect_uri}`;
};

const prepareBaseCognitoAuthProviderOptions = (
  options?: Partial<CognitoAuthProviderOptionsIds>
): CognitoAuthProviderOptionsIds => {
  return {
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID!,
    clientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID!,
    mode: 'oauth',
    hostedUIUrl: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_HOSTED_UI!,
    ...options,
  } as CognitoAuthProviderOptionsIds;
};

export const createCognitoAuthProvider = (options?: Partial<CognitoAuthProviderOptionsIds>): AuthProvider => {
  return CognitoAuthProvider(prepareBaseCognitoAuthProviderOptions(options));
};

export const getUserPool = (): CognitoUserPool => {
  return new CognitoUserPool({
    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID!,
    ClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID!,
  });
};

export const getCognitoAccessToken = async (): Promise<string | null> => {
  const userPool = getUserPool();
  const user = userPool.getCurrentUser();

  if (user) {
    return new Promise((resolve): void => {
      user.getSession((error: Error | null, session: CognitoUserSession | null) => {
        if (error || !session || !session.isValid()) {
          return resolve(null);
        }
        return resolve(session?.getAccessToken().getJwtToken() ?? null);
      });
    });
  }

  return null;
};
