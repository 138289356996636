import { List, Datagrid, TextField, SelectInput, TextInput, DateInput, ShowButton, TopToolbar } from 'react-admin';
import { Pagination } from 'react-admin';
import { LinkToPayments } from '../common/LinkToPayments';
import { ChargeAll } from '../ChargeAll';

const filters = [
    <TextInput source="userId" alwaysOn name="userId"/>,
    <TextInput source="unpaidAmount" alwaysOn name="unpaidAmount"/>,
    <SelectInput
        source="status"
        choices={[
            { id: 'unknown', name: 'Unknown' },
            { id: 'pending', name: 'Pending' },
            { id: 'active', name: 'Active' },
            { id: 'past_due', name: 'Past due' },
        ]}
        resettable
        alwaysOn
    />,
    <DateInput
        source="dateStart"
        alwaysOn
        name="dateStart"/>,
];

const WalletListActions = () => {
    return <TopToolbar>
        <ChargeAll key='chargeAll' />
    </TopToolbar>
}

const PostPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100]}/>;
export const WalletList = () => {
    return (
        <List resource="wallets" pagination={<PostPagination/>} hasCreate={false} exporter={false} filters={filters} actions={<WalletListActions />}>
            <Datagrid>
                <TextField source="userId"/>
                <TextField source="status"/>
                <TextField source="startedDate"/>
                <TextField source="plan.amount"/>
                <TextField source="plan.creditAmount"/>
                <TextField source="balance.availableFunds"/>
                <TextField source="balance.currentBalance"/>
                <TextField source="balance.unpaidAmount"/>
                <ShowButton/>
                <LinkToPayments />
            </Datagrid>
        </List>
    );
};