import { useCallback } from 'react';
import { Datagrid, DateField, List, RaRecord, TextField } from 'react-admin';


export const SettingsList = () => {
    const editTransaction = useCallback((record: RaRecord) => {
        const { id } = record;
        return `${id}`;
      }, []);
    
    return (
        <List resource="settings">
            <Datagrid rowClick={(id, resource, record) => editTransaction(record)}>
                <TextField source="id" />
                <TextField source="orderPaymentSplitPercent" />
                <TextField source="idRequired" />
                <TextField source="allowAccessForEveryone" />
                <TextField source="allowAccessForStaff" />
                <DateField showTime={false} source="allowAccessForSubscriptionCreatedBefore" label="Date" />
                <TextField source="allowAccessForSubscriptionPlans" />
            </Datagrid>
        </List>
    );
};
