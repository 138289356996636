import {
    ChipField,
    Datagrid,
    DateField, DateInput,
    List,
    Loading,
    NumberField,
    Pagination,
    SelectInput,
    TextField,
    UrlField
} from 'react-admin';
import { ConditionalCustomUrlField } from '../common/ConditionalCustomUrlField';
import { useRecordContext } from 'ra-core';
import * as React from 'react';
import { useParams } from 'react-router-dom';

const prepareFilters = () => {
    return [
            <SelectInput
                source='type'
                choices={[
                    { id: 'subscriptionFee', name: 'subscriptionFee' },
                    { id: 'order', name: 'order' },
                    { id: 'credit', name: 'credit' },
                ]}
                resettable
                alwaysOn
            />,
            <SelectInput
                source='status'
                choices={[
                    { id: 'draft', name: 'Draft' },
                    { id: 'processing', name: 'Processing' },
                    { id: 'pending', name: 'Pending' },
                    { id: 'funded', name: 'Funded' },
                    { id: 'done', name: 'Done' },
                    { id: 'failed', name: 'Failed' },
                    { id: 'cancelled', name: 'Cancelled' },

                ]}
                resettable
                alwaysOn
            />,
            <DateInput
                source='dateFrom'
                alwaysOn
                name='dateFrom' />,
            <DateInput
                source='dateTo'
                alwaysOn
                name='dateTo' />,
        ];
};

const PostPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />;
const StripeLinkField = () => {
    const record = useRecordContext();

    if (record.gateway?.id) {
        if (record.gateway.id.startsWith('in_')) {
            return <ConditionalCustomUrlField source='gateway.id' target='_blank' rel='noopener noreferrer'
                                              prefix='https://dashboard.stripe.com/invoices/' />;
        } else if (record.gateway.id.startsWith('pi_')) {
            return <ConditionalCustomUrlField source='gateway.id' target='_blank' rel='noopener noreferrer'
                                              prefix='https://dashboard.stripe.com/payments/' />;
        } else if (record.gateway.checkoutUrl) {
            return <UrlField source='gateway.checkoutUrl' />;
        }
    }
    return <TextField source='gateway.id' />;
};

export const PaymentList = () => {
    const { userId } = useParams();
    if (!userId) {
        return <Loading />;
    }

    return (
        <List resource='payments' pagination={<PostPagination />} hasCreate={false} exporter={false} filters={prepareFilters()} filter={{ userId }}>
            <Datagrid>
                <ChipField source='type' />
                <NumberField source='amount' />
                <ChipField source='status' />
                <StripeLinkField />
                <DateField source='details.billingPeriodStart' showTime={true} />
                <DateField source='details.billingPeriodEnd' showTime={true} />
                <DateField source='createdAt' showTime={true} />
                <DateField source='finishedAt' showTime={true} />
                <DateField source='scheduledDate' showTime={true} />
            </Datagrid>
        </List>
    );
};
