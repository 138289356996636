import IHandler from './IHandler';
import { GetListParams, GetListResult, RaRecord } from 'react-admin';
import { postJson } from '../../../utils/api';

export default class KYCHandler implements IHandler {
    static prevNextToken: Record<number, string> = {};
    static route = '/admin/verification';
    static resourceIdName = 'userId';

    static async getListHandler<RecordType extends RaRecord = any>(
        resource?: string,
        params?: GetListParams
    ): Promise<GetListResult<RecordType>> {
        const query: { [key: string]: any } = {
            limit: params?.pagination.perPage ?? 10,
            ...params?.filter
        };

        const page = params?.pagination.page ?? 1;
        if (page > 1 && KYCHandler.prevNextToken[page - 1] != null) {
            query['nextToken'] = KYCHandler.prevNextToken[page - 1];
        }
        return postJson(`${this.route}/search?${new URLSearchParams(query).toString()}`, {} , process.env.REACT_APP_LOCAL_API)
            .then(async (response) => {
                let { data: responseData } = await response.json();

                const nextToken = responseData.nextToken;
                responseData = responseData?.wallets || [];
                responseData = responseData.map((data: any) => {
                    const verified = data.paymentMethod?.kycVerified === undefined ? 'Not verified' :
                        data.paymentMethod.kycVerified ? 'Verified' : 'Rejected';
                    const result = {
                        ...data,
                        verified
                    };

                    result.id = data[this.resourceIdName];
                    delete result[this.resourceIdName];
                    return {
                        ...result,
                    };
                });
                return {
                    data: responseData,
                    pageInfo: {
                        hasNextPage: !!nextToken,
                        nextPageToken: nextToken ?? undefined,
                    },
                };
            })
            .catch((response) => {
                if (response.status === 400) {
                    return {
                        data: [],
                        total: 0,
                    };
                }
                return Promise.reject({
                    status: response.status,
                    error: response.statusText,
                    message: response.statusText,
                });
            });
    }

    static rejectKYCUsers = async (userIds: string[]) => {
        return postJson(`${this.route}/reject`, { userIds } , process.env.REACT_APP_LOCAL_API)
            .then(async (response) => {
                let { data: responseData } = await response.json();
                return {
                    data: responseData,
                    pageInfo: {
                    },
                };
            })
            .catch((response) => {
                if (response.status === 400) {
                    return {
                        data: [],
                        total: 0,
                    };
                }
                return Promise.reject({
                    status: response.status,
                    error: response.statusText,
                    message: response.statusText,
                });
            });
    }

    static approveKYCUsers = async (userIds: string[]) => {
        return postJson(`${this.route}/approve`, { userIds } , process.env.REACT_APP_LOCAL_API)
            .then(async (response) => {
                let { data: responseData } = await response.json();
                return {
                    data: responseData,
                    pageInfo: {
                    },
                };
            })
            .catch((response) => {
                if (response.status === 400) {
                    return {
                        data: [],
                        total: 0,
                    };
                }
                return Promise.reject({
                    status: response.status,
                    error: response.statusText,
                    message: response.statusText,
                });
            });
    }
}
