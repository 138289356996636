import React from 'react';
import Button from '@mui/material/Button';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router';

export const BackButton = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(-1);
    };

    return (
        <Button
            startIcon={<ArrowBack/>}
            color="primary"
            onClick={handleClick}
        >
            Back
        </Button>
    );
}