import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Grid, Button } from '@mui/material';
import IconCancel from '@mui/icons-material/Cancel';
import IconSave from '@mui/icons-material/Save';
import { useState } from 'react';
import { useNotify, useRefresh } from 'react-admin';
import WalletListHandler from '../../react-admin/providers/handlers/WalletListHandler';
import { SubscriptionPlanSelect } from '../SubscriptionPlanSelect';

const useChangeSubscription = ({
                                   setShow,
                                   userId,
                               }: { setShow: (flag: boolean) => void, userId: string | undefined | null }) => {
    const [reason, setReason] = useState('');
    const [planId, setPlanId] = useState('');
    const [loading, setLoading] = useState(false);
    const notify = useNotify();

    const refresh = useRefresh();
    const close = () => {
        setLoading(false);
        setReason('');
        setPlanId('');
        setShow(false);
    };
    const changeSubscription = async () => {
        setLoading(true);
        if (planId !== '' && userId) {
            WalletListHandler.changeSubscription(userId, {
                    planId,
                    reason: reason === '' ? undefined : reason,
                },
                () => {
                    refresh();
                    close();
                },
                (e: any) => {
                    notify(e.toString(), { type: 'error' });
                    close();
                });
        } else {
            close();
        }
    };

    return {
        reason,
        setReason: (event: any) => setReason(event.target.value),
        planId,
        setPlanId: (value: string) => setPlanId(value),
        changeSubscription,
        close,
        loading,
    };
};

export const ChangeSubscriptionAction = ({ wallet }: { wallet: any }) => {
    const [show, setShow] = useState(false);
    const { reason, setReason, planId, setPlanId, changeSubscription, close, loading } = useChangeSubscription({
        setShow,
        userId: wallet.id,
    });
    return (
        <>
            <Button color='primary' onClick={() => setShow(true)}>Change Plan</Button>
            <Dialog
                fullWidth
                open={show}
                onClose={setShow}
                aria-label='Change Subscription Plan'
            >
                <DialogTitle>Change subscription plan</DialogTitle>
                <DialogContent>
                    <Grid item xs={12}>
                        <TextField id='reason' placeholder='Reason' variant='outlined' fullWidth value={reason}
                                   onChange={setReason} />
                        <SubscriptionPlanSelect showOnlyEnabled={false} planId={planId} currentPlanStripeId={wallet.plan.id} onSelect={setPlanId}  />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={close}
                        disabled={loading}
                    >
                        <IconCancel />
                        <span>Close</span>
                    </Button>
                    <Button
                        onClick={changeSubscription}
                        disabled={!planId || loading}
                    >
                        <IconSave />
                        <span>Change</span>
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};