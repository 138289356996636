import {
    ChipField,
    FunctionField,
    Show,
    SimpleShowLayout,
    TextField,
    TopToolbar,
} from 'react-admin';
import { Grid } from '@mui/material';
import { createDateRenderer } from '../../utils/misc';
import { CancelSubscriptionAction } from '../CancelSubscription';
import { useRecordContext } from 'ra-core';
import { ChangeSubscriptionAction } from '../ChangeSubscription';
import { ChangePaymentDayAction } from '../ChangePaymentDay';
import { ConditionalCustomUrlField } from '../common/ConditionalCustomUrlField';
import { LinkToPayments } from '../common/LinkToPayments';

const WalletActions = () => {
    const record = useRecordContext();

    let actions = [];
    if (record && !['unknown', 'processing', 'pending'].includes(record.status)) {
        actions.push(<CancelSubscriptionAction key="cancel" wallet={record} />);
        actions.push(<ChangeSubscriptionAction key="changePlan" wallet={record} />);
        actions.push(<ChangePaymentDayAction key="changePaymentDay" wallet={record} />);
    }

    return <TopToolbar>
        { actions }
    </TopToolbar>;
};

export const WalletDetails = () => (
    <Show actions={<WalletActions />}>
        <Grid container spacing={2}>
            <Grid xs={6} item>
                <SimpleShowLayout>
                    <TextField source="id" title="User Id"/>
                    <ChipField source="status"/>
                </SimpleShowLayout>
                <SimpleShowLayout>
                    <TextField source="balance.availableFunds"/>
                    <TextField source="balance.currentBalance"/>
                    <TextField source="balance.currentBalance"/>
                    <TextField source="balance.unpaidAmount"/>
                </SimpleShowLayout>
                <SimpleShowLayout>
                    <FunctionField source="startedDate" render={createDateRenderer('startedDate')}/>
                    <FunctionField source="lastPaymentDate" render={createDateRenderer('lastPaymentDate')}/>
                    <FunctionField source="nextPaymentDate" render={createDateRenderer('nextPaymentDate')}/>
                    <ConditionalCustomUrlField source="customerId" target="_blank" rel="noopener noreferrer"
                                    prefix="https://dashboard.stripe.com/customers/"/>
                    <ConditionalCustomUrlField source="gateway.subscription" target="_blank" rel="noopener noreferrer"
                                    prefix="https://dashboard.stripe.com/subscriptions/"/>
                </SimpleShowLayout>
            </Grid>
            <Grid xs={6} item>
                <SimpleShowLayout>
                    <LinkToPayments />
                </SimpleShowLayout>
                <SimpleShowLayout>
                    <TextField source="plan.name"/>
                    <TextField source="plan.title"/>
                    <TextField source="plan.subtitle"/>
                    <TextField source="plan.amount"/>
                    <TextField source="plan.creditAmount"/>
                    <TextField source="plan.currency"/>
                    <TextField source="plan.interval"/>
                    <TextField source="plan.renewalPeriod"/>
                </SimpleShowLayout>
            </Grid>
        </Grid>
    </Show>
);
