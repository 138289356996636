import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import IconCancel from '@mui/icons-material/Cancel';
import IconSave from '@mui/icons-material/Save';
import { useNotify } from 'react-admin';
import WalletListHandler from '../../react-admin/providers/handlers/WalletListHandler';

const useChargeAll = () => {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const notify = useNotify();
    const close = () => {
        setLoading(false);
        setShow(false);
    };


    const chargeAll = async () => {
        setLoading(true);
        WalletListHandler.chargeAll(() => {
            notify('Started the process of charging outstanding payments.')
            close();
        }, (e: any) => {
            notify(e.toString(), { type: 'error' });
            close();
        })

    }

    return {
        chargeAll,
        loading,
        setLoading,
        show,
        setShow,
        close
    }
}

export const ChargeAll = () => {
    const { show, setShow, loading, chargeAll, close } = useChargeAll();

    return (
        <>
            <Button color='primary' onClick={() => setShow(true)}>Charge All</Button>
            <Dialog
                fullWidth
                open={show}
                onClose={setShow}
                aria-label='Charge all'
            >
                <DialogTitle>Charge All</DialogTitle>
                <DialogContent>
                    <Grid item xs={12}>
                        This action attempts to charge all outstanding subscription and store payments.
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={close}
                        disabled={loading}
                    >
                        <IconCancel />
                        <span>Cancel</span>
                    </Button>
                    <Button
                        onClick={chargeAll}
                        disabled={loading}
                    >
                        <IconSave />
                        <span>Charge</span>
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    )
}