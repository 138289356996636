import React from 'react';
import { Card, CardContent } from '@mui/material';
import {
    Edit,
    SimpleForm,
    DateInput,
    BooleanInput,
    Loading,
    useRecordContext,
    TextInput,
    Toolbar,
    SaveButton,
    SelectArrayInput,
    useGetList,
    NumberInput,
} from 'react-admin';
import { useParams } from 'react-router-dom';


const SettingsEditToolbar = (props: any) => (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
)

const SubscriptionPlanMultiSelect = () => {
  const { data, isLoading, error } = useGetList('plans', {
      pagination: {
          perPage: 100,
          page: 0
      },
      filter: {
          enabled: true
      }
  });

  if (isLoading) {
      return <Loading></Loading>
  }

  if (error) {
      return <div>Failed loading plans.</div>
  }

  return <SelectArrayInput source="allowAccessForSubscriptionPlans" choices={
      data?.map((d) => {
          return {
              id: d.id,
              name: d.name
          }
      })} />
}

const SettingsEdit = () => {
  const { id } = useParams();
  
  if (!id) {
    return <Loading />;
  }

  const ProviderView = () => {
    const record = useRecordContext();
    if (!record) return null;
    return <pre>{JSON.stringify(record.provider, null, '\t')}</pre>;
  };

  const transformValues = (values: any) => {
      return {
          ...values,
          orderLimitEmailRecipientList: values.orderLimitEmailRecipientList.filter((v: string) => !!v),
          userReachedCreditLimitEmailRecipientList: values.userReachedCreditLimitEmailRecipientList.filter((v: string) => !!v)
      }
  }
  return (
    <Card>
      <CardContent>
      <ProviderView />
        <Edit title="Setting" resource="settings" actions={false} redirect={false} mutationMode={'pessimistic'} transform={transformValues}>
          <SimpleForm toolbar={<SettingsEditToolbar />}>
            <BooleanInput source="idRequired" />
            <TextInput source="orderPaymentSplitPercent" />
            <BooleanInput source="allowAccessForEveryone" />
            <BooleanInput source="allowAccessForStaff" />
            <DateInput source="allowAccessForSubscriptionCreatedBefore" />
            <SubscriptionPlanMultiSelect />
            <NumberInput source="orderLimitPerHour" min={1} defaultValue={10} parse={(value) => parseInt(value, 10) ?? 10}/>
            <NumberInput source="orderLimitPerDay" min={1} defaultValue={10} parse={(value) => parseInt(value, 10) ?? 10}/>
            <NumberInput source="orderLimitPerPeriod" min={1} defaultValue={10} parse={(value) => parseInt(value, 10) ?? 10}/>
            <BooleanInput source="orderLimitEmailEnabled" />
            <TextInput source="orderLimitEmailRecipientList" format={(v) => (v ?? []).join(', ')} parse={(value: string) => value.split(',').map((v: string) => v.trim())} />
            <BooleanInput source="userReachedCreditLimitEmailEnabled" />
            <TextInput source="userReachedCreditLimitEmailRecipientList" format={(v) => (v ?? []).join(', ')} parse={(value: string) => value.split(',').map((v: string) => v.trim())} />
          </SimpleForm>
          
        </Edit>
      </CardContent>
    </Card>
  );
};

export default SettingsEdit;
