export const AUTH_TOKEN_KEY = 'nps-admin-access-token';
export const useAuthentication = () => {
    const getAuthToken = (): string | null => localStorage.getItem(AUTH_TOKEN_KEY);
    const isAuthenticated = () => {
        const token = getAuthToken();
        if (token === null) {
            return false;
        }
        return true;
    };
    const clearAuthToken = (): void => localStorage.removeItem(AUTH_TOKEN_KEY);

    const saveAuthToken = (token: string): void => localStorage.setItem(AUTH_TOKEN_KEY, token);

    return {
        isAuthenticated,
        clearAuthToken,
        saveAuthToken,
    };
};
