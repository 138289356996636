import {
    DataProvider as RaDataProvider,
    CreateParams,
    CreateResult,
    DeleteManyResult,
    DeleteResult,
    GetListParams,
    GetListResult,
    GetManyReferenceResult,
    GetManyResult,
    GetOneParams,
    GetOneResult,
    RaRecord,
    UpdateManyResult,
    UpdateParams,
    UpdateResult,
} from 'react-admin';

import { putJson, getJson, postJson } from '../../utils/api';
import StatsHandler from './handlers/StatsHandler';
import SubscriptionPlanListHandler from './handlers/SubscriptionPlanListHandler';
import SubscriptionsHandler from './handlers/SubscriptionsHandler';
import WalletListHandler from './handlers/WalletListHandler';
import SettingsHandler from './handlers/SettingsHandler';
import KYCHandler from './handlers/KYCHandler';
import PaymentListHandler from './handlers/PaymentListHandler';
const resourceHandlerMap = new Map<string, any>([
    ['stats', StatsHandler],
    ['subscriptions', SubscriptionsHandler],
    ['wallets', WalletListHandler],
    ['settings', SettingsHandler],
    ['plans', SubscriptionPlanListHandler],
    ['kyc', KYCHandler],
    ['payments', PaymentListHandler],
]);

const getResourceName = (resource: string): string => {
    const resourceComponents = resource.split('/');
    return resourceComponents.length > 0 ? resourceComponents[resourceComponents.length - 1] : resource;
};

class DataProvider implements RaDataProvider {
    async getList<RecordType extends RaRecord = any>(
        resource: string,
        params: GetListParams
    ): Promise<GetListResult<RecordType>> {
        const resourceName = getResourceName(resource);
        const handler = resourceHandlerMap.get(resourceName);
        if (handler?.getListHandler) {
            return handler.getListHandler(resource, params);
        }

        return getJson(`/${resource}`).then(async (response) => {
            let { data: responseData } = await response.json();

            responseData = responseData.Items ?? responseData;
            responseData = responseData?.length > 0 ? responseData : [];

            return {
                data: responseData,
                total: responseData.length,
            };
        });
    }

    async getOne<RecordType extends RaRecord = any>(
        resource: string,
        params: GetOneParams<any>
    ): Promise<GetOneResult<RecordType>> {
        const { id } = params;
        params.id = id === 'undefined' ? '' : id;

        const resourceName = getResourceName(resource);
        const handler = resourceHandlerMap.get(resourceName);
        if (handler?.getOneHandler) {
            return handler.getOneHandler(resource, params);
        }

        return getJson(`/${resource}/${id}`).then(async (response) => {
            const { data } = await response.json();
            return {
                data,
            };
        });
    }

    async getMany<RecordType extends RaRecord = any>(): Promise<GetManyResult<RecordType>> {
        return Promise.reject(new Error('Method not available'));
    }

    // this should filter by an id of a related record, the api doesn't have this feature
    async getManyReference<RecordType extends RaRecord = any>(): Promise<GetManyReferenceResult<RecordType>> {
        return Promise.reject(new Error('Method not available'));
    }

    async update<RecordType extends RaRecord = any>(
        resource: string,
        params: UpdateParams<any>
    ): Promise<UpdateResult<RecordType>> {
        const { id } = params;
        params.id = id === 'undefined' ? '' : id;

        const resourceName = getResourceName(resource);
        const handler = resourceHandlerMap.get(resourceName);
        if (handler?.updateHandler) {
            return handler.updateHandler(resource, params);
        }
        const payload = params.data;

        return putJson(`/${resource}/${id}`, payload).then(async (response) => {
            const { data } = await response.json();

            return {
                data,
            };
        });
    }

    // this should update an array of ids, the api doesn't have this feature
    async updateMany<RecordType extends RaRecord = any>(): Promise<UpdateManyResult<RecordType>> {
        return Promise.reject(new Error('Method not available'));
    }

    async create<RecordType extends RaRecord = any>(
        resource: string,
        params: CreateParams<any>
    ): Promise<CreateResult<RecordType>> {
        const payload = params.data;

        const resourceName = getResourceName(resource);
        const handler = resourceHandlerMap.get(resourceName);
        if (handler?.createHandler) {
            return handler.createHandler(resource, params);
        }

        return postJson(`/${resource}`, payload).then(async (response) => {
            const { data } = await response.json();

            return {
                data,
            };
        });
    }

    // the api doesn't have any delete method yet
    async delete<RecordType extends RaRecord = any>(): Promise<DeleteResult<RecordType>> {
        return Promise.reject(new Error('Method not available'));
    }

    // this should delete an array of ids, the api doesn't have this feature
    async deleteMany<RecordType extends RaRecord = any>(): Promise<DeleteManyResult<RecordType>> {
        return Promise.reject(new Error('Method not available'));
    }
}

const dataProvider = new DataProvider()

export default dataProvider;
