import React, { useCallback } from 'react';

import { Button, DashboardMenuItem, Menu as RaMenu, MenuItemLink, useLogout } from 'react-admin';
import UserIcon from '@mui/icons-material/Group';
import SettingsIcons from '@mui/icons-material/Settings';

export const Menu: React.FC = () => {
    const logout = useLogout();
    const handleLogout = useCallback(() => {
        logout();
    }, [logout]);

    return (
        <RaMenu>
            <DashboardMenuItem />
            <MenuItemLink to={{ pathname: "/wallets", search: `filter=${JSON.stringify({ status: "active" })}&perPage=100` }} primaryText="Wallets" leftIcon={<UserIcon />} />
            <MenuItemLink to="/settings/store" primaryText="Settings" leftIcon={<SettingsIcons />} />
            <MenuItemLink to={{ pathname: "/plans", search: `filter=${JSON.stringify({ enabled: "true" })}`}} primaryText="Plans" leftIcon={<SettingsIcons />} />
            <MenuItemLink to="/kyc" primaryText="KYC" leftIcon={<SettingsIcons />} />
            <Button onClick={handleLogout}>
                <>Logout</>
            </Button>
        </RaMenu>
    );
};
