import { Button, Datagrid, DateField, List, TextField, useListContext, useRefresh } from 'react-admin';
import KYCHandler from '../../react-admin/providers/handlers/KYCHandler';

const useKYCActions = () => {
    const refresh = useRefresh();
    const rejectKYCUsers = async (userIds: string[]) => {
        await KYCHandler.rejectKYCUsers(userIds);
        refresh();
    }
    const approveKYCUsers = async (userIds: string[]) => {
        await KYCHandler.approveKYCUsers(userIds);
        refresh();
    }

    return {
        rejectKYCUsers,
        approveKYCUsers
    };
}

const KYCListActions = () => {
    const { selectedIds } = useListContext();
    const { rejectKYCUsers, approveKYCUsers } = useKYCActions();
    return (
        <>
            <Button label="Approve" onClick={() => approveKYCUsers(selectedIds)}></Button>
            <Button label="Reject" onClick={() => rejectKYCUsers(selectedIds)}></Button>
        </>
    )
}

export const KYCList = () => {
    return (
        <List resource="kyc">
            <Datagrid bulkActionButtons={<KYCListActions />}>
                <TextField source="verified" label="Verification status"/>
                <TextField source="paymentMethod.name" label="Name in card" />
                <TextField source="paymentMethod.fullName" label="Full name" />
                <DateField source="dateUpdate" />
            </Datagrid>
        </List>
    );
};
