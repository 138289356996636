import IHandler from './IHandler';
import { GetListParams, GetListResult, RaRecord } from 'react-admin';
import { getJson, postJson } from '../../../utils/api';

export default class SubscriptionsHandler implements IHandler {
    static prevNextToken: Record<number, string> = {};
    static route = '/admin/wallet/listall';
    static resourceIdName = 'timestamp';

    static async getListHandler<RecordType extends RaRecord = any>(
        resource?: string,
        params?: GetListParams
    ): Promise<GetListResult<RecordType>> {
        const query: { [key: string]: any } = {
            pageSize: params?.pagination.perPage ?? 10,
        };
        const page = params?.pagination.page ?? 1;
        if (page > 1 && SubscriptionsHandler.prevNextToken[page - 1] != null) {
            query['nextToken'] = SubscriptionsHandler.prevNextToken[page - 1];
        }
        return getJson(`${this.route}?${new URLSearchParams(query).toString()}`, process.env.REACT_APP_LOCAL_API)
            .then(async (response) => {
                let { data: responseData } = await response.json();
                const nextToken = responseData?.nextToken;
                SubscriptionsHandler.prevNextToken[page] = JSON.stringify(nextToken);
                responseData = responseData?.wallets?.map((data: any) => {
                    const result = {
                        ...data,
                    };

                    result.id = data[this.resourceIdName];
                    delete result[this.resourceIdName];
                    return {
                        ...result,
                    };
                });
                return {
                    data: responseData,
                    pageInfo: {
                        hasNextPage: !!nextToken,
                        nextPageToken: nextToken ?? undefined,
                    },
                };
            })
            .catch((response) => {
                if (response.status === 400) {
                    return {
                        data: [],
                        total: 0,
                    };
                }
                return Promise.reject({
                    status: response.status,
                    error: response.statusText,
                    message: response.statusText,
                });
            });
    }

    static async cancelSubscription(userId: string, reason: string, onSuccess: () => void, onFailure: () => void) {
        return postJson(`/admin/wallet/${userId}/subscription/cancel`, { reason }, process.env.REACT_APP_LOCAL_API)
        .then(async (response) => {
            const { data } = await response.json();

            const d = data;
            d.id = d[this.resourceIdName];
            delete d[this.resourceIdName];
            onSuccess();
            return {
                data: {
                    ...d,
                },
            };
        })
        .catch((response) => {
            if (response.status === 400) {
                return {
                    data: [],
                    total: 0,
                };
            }
            onFailure();
            return Promise.reject();
        });
    }
}
