import {
    BooleanField,
    BooleanInput,
    Datagrid,
    List,
    Pagination,
    ShowButton,
    TextField,
    EditButton,
    useRecordContext,
    Button, useRefresh, useNotify, useUpdate,
} from 'react-admin';
import CustomUrlField from '../../react-admin/components/CustomUrlField';

const filters = [
    <BooleanInput source="isDefault" name="default" alwaysOn/>,
    <BooleanInput source="isEnabled" name="enabled" alwaysOn/>,
];

const PostPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100]}/>;

const EnableDisableButton = () => {
    const record = useRecordContext();
    const { isEnabled } = record;
    const refresh = useRefresh();
    const notify = useNotify();
    const [update, { isLoading }] = useUpdate('plans', {
        id: record.id,
        data: { isEnabled: !isEnabled },
        previousData: record
    }, {
        onSuccess: () => {
            refresh();
            notify('Status updated');
        },
        onError: (error) => {
            notify(`Status update error: ${error}`, { type: 'error' });
        },
    });
    const handleClick = () => {
        update();
    };
    return <Button disabled={isLoading} label={isEnabled ? 'Disable' : 'Enable'} onClick={handleClick}></Button>;
};

export const SubscriptionPlanList = () => {
    return (
        <List resource="plans" pagination={<PostPagination/>} hasCreate={true} exporter={false} filters={filters}>
            <Datagrid>
                <TextField source="name"/>
                <TextField source="title"/>
                <TextField source="subtitle"/>
                <TextField source="amount"/>
                <TextField source="creditAmount"/>
                <BooleanField source="isDefault"/>
                <BooleanField source="isEnabled"/>
                <CustomUrlField source="stripePlanId" target="_blank" rel="noopener noreferrer"
                                prefix="https://dashboard.stripe.com/prices/"/>
                <ShowButton/>
                <EditButton/>
                <EnableDisableButton/>
            </Datagrid>
        </List>
    );
};