import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Grid, Button } from '@mui/material';
import IconCancel from '@mui/icons-material/Cancel';
import IconSave from '@mui/icons-material/Save';
import { useState } from 'react';
import { useNotify, useRefresh } from 'react-admin';
import WalletListHandler from '../../react-admin/providers/handlers/WalletListHandler';

const useCancelSubscription = ({
                                   setShow,
                                   subscriptionId,
                               }: { setShow: (flag: boolean) => void, subscriptionId: string | undefined }) => {
    const [reason, setReason] = useState('');
    const [loading, setLoading] = useState(false);
    const notify = useNotify();

    const refresh = useRefresh();
    const close = () => {
        setLoading(false);
        setReason('');
        setShow(false);
    };
    const cancelSubscription = async () => {
        setLoading(true);
        if (reason !== '' && subscriptionId !== undefined) {
            WalletListHandler.cancelSubscription(subscriptionId, reason,
                () => {
                    refresh();
                    close();
                },
                (e: any) => {
                    notify(e.toString(), { type: 'error' });
                    close();
                });
        } else {
            close();
        }
    };

    return {
        reason,
        setReason: (event: any) => setReason(event.target.value),
        cancelSubscription,
        close,
        loading,
    };
};

export const CancelSubscriptionAction = ({ wallet }: { wallet: any }) => {
    const [show, setShow] = useState(false);
    const { reason, setReason, cancelSubscription, close, loading } = useCancelSubscription({
        setShow,
        subscriptionId: wallet.id,
    });
    return (
        <>
            <Button color='primary' onClick={() => setShow(true)}>Cancel</Button>
            <Dialog
                fullWidth
                open={show}
                onClose={setShow}
                aria-label='Cancel Subscription'
            >
                <DialogTitle>Cancel subscription</DialogTitle>
                <DialogContent>
                    <Grid item xs={12}>
                        <TextField id='reason' placeholder='Reason' variant='outlined' fullWidth value={reason}
                                   onChange={setReason} />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={close}
                        disabled={loading}
                    >
                        <IconCancel />
                        <span>Close</span>
                    </Button>
                    <Button
                        onClick={cancelSubscription}
                        disabled={reason === '' || loading}
                    >
                        <IconSave />
                        <span>Save</span>
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};